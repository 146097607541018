import { get, post } from './request'

// Survey Form
const postForm = (data) => post('/subscriptions', data)

const getCategories = () => get('/subscriptions/categories')

export {
  postForm,
  getCategories,
}
