import React from 'react'
import styled from 'styled-components'
import InputBase from '@lib/ui/core/InputBase'
import Box from '@lib/ui/core/Box';

const InputRoot = styled(Box)`
  width: 100%;
  height: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  background-color: #F5F5F5;
`

const FormInput = ({ placeholder, border, ...props }) => (
  <InputRoot px={1} border={border ? '1px solid #F54444' : 'none'}>
    <Box flex={1} paddingLeft=".5rem">
      <InputBase
        fullWidth
        fontSize="body2.fontSize"
        placeholder={placeholder}
        {...props}
      />
    </Box>
  </InputRoot>
)

export default FormInput
