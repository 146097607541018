import React from "react"
import Typography from "@lib/ui/core/Typography"
import Box from "@lib/ui/core/Box"

const ErrorMsg = ({ children, ...props }) => (
  <Box color="#F83030" {...props}>
    <Typography>{children}</Typography>
  </Box>
)

export default ErrorMsg
